import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getGallery } from "../Service/Api";
import LazyLoad from 'react-lazyload';

const HomeGallery = () => {
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getGallery();
      setData(topperData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("galleryData", data);
  }, [data]);

  const emptyArray = [
    {
      title: "Gallery Title",
      day: "DD",
      month: "MM",
      attachments: "https://webapi.entab.info/api/image/STMST/public/Images/gallery-pic.jpg",
      description: "Stay tuned for latest updates",
    },
    {
      title: "Gallery Title",
      day: "DD",
      month: "MM",
      attachments: "https://webapi.entab.info/api/image/STMST/public/Images/gallery-pic.jpg",
      description: "Stay tuned for latest updates",
    },
    {
      title: "Gallery Title",
      day: "DD",
      month: "MM",
      attachments: "https://webapi.entab.info/api/image/STMST/public/Images/gallery-pic.jpg",
      description: "Stay tuned for latest updates",
    },
    {
      title: "Gallery Title",
      day: "DD",
      month: "MM",
      attachments: "https://webapi.entab.info/api/image/STMST/public/Images/gallery-pic.jpg",
      description: "Stay tuned for latest updates",
    },
    {
      title: "Gallery Title",
      day: "DD",
      month: "MM",
      attachments: "https://webapi.entab.info/api/image/STMST/public/Images/gallery-pic.jpg",
      description: "Stay tuned for latest updates",
    },
    {
      title: "Gallery Title",
      day: "DD",
      month: "MM",
      attachments: "https://webapi.entab.info/api/image/STMST/public/Images/gallery-pic.jpg",
      description: "Stay tuned for latest updates",
    },
    {
      title: "Gallery Title",
      day: "DD",
      month: "MM",
      attachments: "https://webapi.entab.info/api/image/STMST/public/Images/gallery-pic.jpg",
      description: "Stay tuned for latest updates",
    },
  ];

  return (
    <>
      <div className="row">
        <Slider {...settings}>
          {data.length >= 4 &&
            data.map((item, index) => (
              <div className="item" key={index}>
                <div className="infrastructure">
                  <div className="infrastructureimg">
                  <LazyLoad> <img
                      src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`}
                      className="img-fluid"
                      alt="St. Mary’s Convent Sr. Sec. School, Tigaon"
                    /></LazyLoad>
                  </div>
                  <div className="infrastructuretitle">
                    <h3> {item.title}</h3>
                  </div>
                  <div className="infrastructuredesc">
                    <p> {item.description}</p>
                  </div>
                </div>
              </div>
            ))}
     {
  data.length < 4 && data.length > 0 &&
  data.map((item, index) => 
    item.attachments?.map((img, imgIndex) => (
      <div className="item" key={`${index}-${imgIndex}`}>
        <div className="infrastructure">
          <div className="infrastructureimg">
          <LazyLoad>  <img
              src={`https://webapi.entab.info/api/image/${img}`}
              className="img-fluid"
              alt={`Image for ${item.title}`}
            /></LazyLoad>
          </div>
          <div className="infrastructuretitle">
            <h3>{item.title}</h3>
          </div>
          <div className="infrastructuredesc">
            <p>{item.description}</p>
          </div>
        </div>
      </div>
    ))
  )
}

        </Slider>
      </div>
    </>
  );
};

export default HomeGallery;
