import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getHighlight } from '../Service/Api';
import LazyLoad from 'react-lazyload';
const Header = () => {

     const marqueeRef = useRef(null);
     const handleMouseOver = () => {
       marqueeRef.current.stop();
     };  
     const handleMouseOut = () => {
       marqueeRef.current.start();
     };
     const [data, setData] = useState([]);
   useEffect(() => {
     const getData = async () => {
       const data = await getHighlight();
       console.log(data);
       setData(data);
     };
     getData();
   }, []);
 
 
   const emptyArray = [
     { description: "Stay tuned for latest updates" },   
     { description: "Stay tuned for latest updates" },
     { description: "Stay tuned for latest updates" },
   ];
 

  return (
    <>
    <div className="topheader">
    <div className="topheaderleft">
        
         <p>Announcement</p>
    </div>
       <div className="highlights">
       <marquee onMouseOver={(event) => event.currentTarget.stop()}   onMouseOut={(event) => event.currentTarget.start()}>
                  
           <p>
  {data.length > 0 ? (
    data.map((item, index) => (
      <a
        href={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
        target="_blank"
        rel="noopener noreferrer"
        key={index}
      >
        {item.description}{" "}
        {item.attachments.length > 0 && (
          <i style={{ color: "white" }} className="bi bi-paperclip"></i>
        )}
      </a>
    ))
  ) : (
    emptyArray.map((data, index) => (
      <a key={index}>{data.description}</a>
    ))
  )}
</p>
                   
           </marquee>
       </div>
       <div className="topheaderright">
           <Link to="https://www.youtube.com/@stmarysconventschool8865" target="_blank">
            <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/youtube.png" className="img-fluid"/></LazyLoad>
            </Link>
           
           <Link to="https://www.facebook.com/profile.php?id=100069065534269&mibextid=ZbWKwL" target="_blank">
            <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/facebook.png" className="img-fluid"/></LazyLoad>
            </Link>
          <Link to="#" target="_blank">
            <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/twiter.png" className="img-fluid"/></LazyLoad>
            </Link>
          <Link to="#" target="_blank">
            <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/insta.png" className="img-fluid"/></LazyLoad>
            </Link>
          
       </div>
  </div>
  <div className="midheader">
      <div className="midheaderleft">
           <Link to="/">
          <LazyLoad>  <img src="https://webapi.entab.info/api/image/STMST/public/Images/logo.png" className="img-fluid"/></LazyLoad></Link>
      </div>
      <div className="midheadermid">
             <p><Link to="https://api.whatsapp.com/send/?phone=%2B919717528625&text&type=phone_number&app_absent=0" target="_blank"> 
             <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/whatapp.png" className="img-fluid"/></LazyLoad> +91 9717528625</Link></p>
           <p><Link to="mailto:stmarysconventschool.1993@gmail.com">
            <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/mail.png" className="img-fluid"/></LazyLoad>   stmarysconventschool.1993@gmail.com </Link> </p>
      </div>
      <div className="midheaderright">
           <button> <p> <a target='_blank' href='https://entab.online/SMCTHR'>  PARENT LOGIN</a> </p>
            <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/Arrow2.png" className="img-fluid"/></LazyLoad></button>
      </div>
  </div>
  <div className="headerbottom">
  <nav class="navbar navbar-expand-lg">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"><i class="bi bi-list"></i></span>
  </button>
  <div class="collapse navbar-collapse" id="main_nav">
  <ul class="navbar-nav">
     <li className="nav-item"><Link className="nav-link" to="/">
      <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/home.png" className="img-fluid"/></LazyLoad>
     </Link></li>
        <li className="nav-item dropdown">
          <div className="nav-link" to="#" data-bs-toggle="dropdown"> About Us </div>
               <ul className="dropdown-menu">
                  <li><NavLink to="AboutUs" className="dropdown-item">About Us</NavLink></li>
                  <li><NavLink to="PrincipalMessage" className="dropdown-item">Principal's Message</NavLink></li>
                  <li><NavLink to="Infrastructure" className="dropdown-item">Infrastructure</NavLink></li>
               </ul>
         </li>
          <li className="nav-item"><Link className="nav-link" to="CBSEDisclosure">CBSE Disclosure</Link></li>
        <li className="nav-item dropdown">
          <div className="nav-link" to="#" data-bs-toggle="dropdown"> Academic </div>
               <ul className="dropdown-menu">
                  <li><NavLink to="SchoolUniform" className="dropdown-item">School Uniform</NavLink></li>
                  <li><NavLink to="SchoolTiming" className="dropdown-item">School Timing</NavLink></li>
                  <li><NavLink to="OurGoals" className="dropdown-item">Our Goals</NavLink></li>
                  <li><NavLink to="Discipline" className="dropdown-item">Discipline</NavLink></li>
                  <li><NavLink to="ActivityPlanner" className="dropdown-item">Activity Planner</NavLink></li>
               </ul>
         </li>
         <li className="nav-item dropdown">
        <div className="nav-link" to="#" data-bs-toggle="dropdown"> Co-Curricular </div>
             <ul className="dropdown-menu">
                <li><NavLink to="InterSchoolQuizCompetition" className="dropdown-item">Inter School Quiz Competition</NavLink></li> 
             </ul>
       </li>
       
        <li className="nav-item dropdown">
          <div className="nav-link" to="#" data-bs-toggle="dropdown"> Admission </div>
               <ul className="dropdown-menu">
                  <li><NavLink to="Admission" className="dropdown-item">Admission</NavLink></li>
                  <li><NavLink to="FeeRules" className="dropdown-item">Fee Rules</NavLink></li>
                  <li><NavLink to="FeeStructure" className="dropdown-item">Fee Structure</NavLink></li>
                  <li><NavLink to="ModeOfPayment" className="dropdown-item">Mode Of Payment</NavLink></li>
               </ul>
         </li>
         <li className="nav-item"><Link className="nav-link" to="">student corner</Link></li>
        
        <li className="nav-item dropdown">
        <div className="nav-link" to="#" data-bs-toggle="dropdown"> Gallery </div>
             <ul className="dropdown-menu">
                <li><NavLink to="PhotoGallery" className="dropdown-item">Photo Gallery</NavLink></li>
                <li><NavLink to="VideoGallery" className="dropdown-item">Video Gallery</NavLink></li>
             </ul>
       </li>
        <li className="nav-item"><Link className="nav-link" to="">Careers</Link></li>
        <li className="nav-item"><Link className="nav-link" to="ContactUs">Contact</Link></li>
  </ul>
  </div> 
</nav>
          
   </div>
    </>
  )
}

export default Header
