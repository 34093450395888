import React from 'react'
import LazyLoad from 'react-lazyload';
import OwlCarousel from 'react-owl-carousel';
const Facilities = () => {
  const options = {
    margin: 30,
    dots:false,
    nav: true,
    navText : ["<i class='bi bi-arrow-left'></i>","<i class='bi bi-arrow-right'></i>"],
    responsiveClass: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items:1,
        },
        600: {
            items: 3,
        },
        700: {
            items: 4,
        },
        1000: {
            items:6,

        },
        1200: {
            items:6,

        }
    },
};
  return (
     <> 
     <div className="row">
     <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
     <div className="item">
     <div className="facilitiesbox">
     <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/smartclass.png" className="img-fluid"/></LazyLoad>
         <h3>SMART cLASSES</h3>
     </div>
</div>
<div className="item">
     <div className="facilitiesbox">
     <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/library.png" className="img-fluid"/></LazyLoad>
         <h3>School Library</h3>
     </div>
</div>
<div className="item">
     <div className="facilitiesbox">
         <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/computelab.png" className="img-fluid"/></LazyLoad>
         <h3>Computer Lab</h3>
     </div>
</div>
<div className="item">
     <div className="facilitiesbox">
         <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/chemistrylab.png" className="img-fluid"/></LazyLoad>
         <h3>Chemistry lab</h3>
     </div>
</div>
<div className="item">
     <div className="facilitiesbox">
         <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/biology.png" className="img-fluid"/></LazyLoad>
         <h3>Biology Lab</h3>
     </div>
</div>
<div className="item">
     <div className="facilitiesbox">
         <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/physicslab.png" className="img-fluid"/></LazyLoad>
         <h3>Physics lab</h3>
     </div>
</div>
        </OwlCarousel> 
     </div>
     </>
  )
}

export default Facilities