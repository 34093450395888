import React from 'react'
import HomeMessage from'../Component/HomeMessage'
import HomeNews from'../Component/HomeNews'
import Facilities from'../Component/Facilities'
import HomeEvent from'../Component/HomeEvent'
import HomeTopperXII from'../Component/HomeTopperXII'
import HomeTopperX from'../Component/HomeTopperX'
import HomeGallery from'../Component/HomeGallery'
import Calender from'../Component/Calender'
import HomeSlider from'../Component/HomeSlider'
import PopupModal from'../Component/PopupModal'
import { Link } from 'react-router-dom'
import FooterForm from '../Component/FooterForm'
import LazyLoad from 'react-lazyload'
const Home = () => {
  return (
    <>
     <PopupModal/>
     <HomeSlider/>

     <div className="midsection">
         <div className="aboutus">
            <div className="aboutusimg">
                <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/about.png" className="img-fluid"/></LazyLoad>
            </div>
            <div className="aboutusdesc">
                <h1>Our Mission & Vision</h1>
                <p>The Institution is meant for a child’s first step towards organized learning, participation, sharing and socializing. Main objective of our school is the realization of full potential, i.e physical, mental, moral, social, spiritual and intellectual, in each child and to instill the qualities, values and attitudes for success in his/ her life and to become a good human being.</p>
            </div>
         </div>
        
         <div className="newssec">
              <div className="container">
                  <div className="row">
                       <div className="col-lg-12">
                             <div className="maintitle">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/news.gif" className="img-fluid"/></LazyLoad>
                                <h2>Latest  News</h2>
                            </div>
                        </div>
                          <HomeNews/>
                          <button className='m-auto d-block w-auto mt-4'><Link to="/News">
                         <LazyLoad>    <img src="https://webapi.entab.info/api/image/STMST/public/Images/Arrow2.png" className="img-fluid"/></LazyLoad> | View All</Link> </button>
                       </div>
                  </div>
              </div>
              </div>
              <div className="aboutschool">
              <div className="container">
                <div className="row">
                     <div className="col-lg-12">
                           <div className="maintitle">
                              <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/school.gif" className="img-fluid"/></LazyLoad>
                              <h2> About School</h2>
                          </div>
                      </div>
                      <div className="col-lg-12">
                          <p><b> St. Mary’s Convent Sr. Sec. School </b> is a Catholic Institution started in <b>1993 </b>. It
                          is directed and managed by Bethany Sisters and educationalists. The school is
                          founded with a great vision to uplift the community and society not only intellectually
                          but also spiritually and morally. We are guided by sublime principles of genuine
                          education. Co-education, comprehensive curriculum, modern approaches in
                          education, holistic formation and personal development are the basic thrust of
                          the school.  </p>
                          <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/homeabout.png" className="img-fluid homeaboutimg"/></LazyLoad>
                          <button> <p>
                             <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/Arrow2.png" className="img-fluid"/></LazyLoad> | View All </p> </button>
                      </div>
                   </div>
                </div>
              </div>
              <div className="homemsg">
              <div className="container-fluid flwidth">
                <div className="row">
                     <div className="col-xl-3 col-lg-12">
                        <div className="homemsgtitle">
                           <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/msgtitlebg.png" className="img-fluid"/></LazyLoad>
                          <div className="maintitle">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/message.gif" className="img-fluid"/></LazyLoad>
                                <h2>  Message section</h2>
                            </div>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-12">
                           <div className="messagebox">
                                 <div className="messagetop">
                                    <div className="messageboxtitle">
                                         <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/msgicn.png" className="img-fluid"/></LazyLoad>
                                          <h3>Principal's Message</h3>
                                    </div>
                                    <div className="messageboximg">
                                      <p>Sr. Tomsy SIC <span> Principal</span></p>
                                      <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/principal.jpg" className="img-fluid"/></LazyLoad>
                                    </div>
                                 </div>
                                 <div className="messagedesc">
                                 <p>As we embark on the academic year 2024-2025, it is with great pride and enthusiasm that I extend my warmest greetings to each and every one of you. Here at St. Mary's Convent School, we are not just a community of learners, but a family bound together by the shared values of excellence, integrity, and compassion.</p>

                                 <p>Parents, I invite you to take a moment to reflect on the privilege it is to be entrusted with the education and upbringing of such remarkable young individuals. Your decision to enroll your children in St. Mary's Convent School speaks volumes about your commitment to their future and your belief in the transformative power of education. Let us carry this pride with us as we journey through the academic year ahead.</p>
                                 <p>Students, you are the heart and soul of our school. Your curiosity, creativity, and determination inspire us every day. As you flip through the pages of this Almanac, remember that each entry represents an opportunity for growth, learning, and achievement. Embrace the challenges, celebrate the successes, and never forget the incredible potential that lies within each of you.</p>
                                 </div>
                           </div>
                            <HomeMessage/>
                      </div>
                 </div>
               </div>
            </div>
            <div className="facilities">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="maintitle">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/facilitesicn.gif" className="img-fluid"/></LazyLoad>
                            <h2>School Facilities </h2>
                        </div>
                        <Facilities/>
                    </div>
                   
                 
                </div>
                </div>
                </div>
                <div className="homenews">
                <div className="container-fluid flwidth">
                <div className="row">
                    <div className="col-lg-12">
                       <div className="maintitle">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/eventicn.gif" className="img-fluid"/></LazyLoad>
                            <h2>Events, Updates & <span> Academic Calendar </span> </h2>
                        </div>
                    </div>
            <div className="col-xl-4 col-lg-6">
                <Calender/>
            </div>

            <div className="col-xl-8 col-lg-6">
                    <HomeEvent/>
                <div className="mx-auto d-block text-center">
                <button><Link to="Events"> <p> 
                  <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/Arrow2.png" className="img-fluid"/></LazyLoad> | View All </p></Link> </button>
                </div>
            </div>
                 
     </div>
  </div>
</div>

<div className="hometopper">
        <div className="container-fluid">
        <div className="row">
            <div className="col-lg-4">
               <div className="toppermaintitle">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/trophyicn.gif" className="img-fluid"/></LazyLoad>
                    <h2> School Toppers </h2>
                    <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/topperbigicn.png" className="img-fluid toppertry"/></LazyLoad>
                </div>
              </div> 
              <div className="col-lg-8">
                      <div className="row">
                           <div className="col-lg-12 mx-auto d-block text-center">
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/classicn.png" className="img-fluid"/></LazyLoad>
                           </div>
                      </div>           
                      <div className="row">
                        <div className="col-lg-11">
                           <HomeTopperXII/>
                        </div>
                        <div className="col-lg-1 topperbtn">
                           <Link to="/TopperXII"><button><i class="bi bi-arrow-right"></i></button></Link>
                        </div>
                      </div>
                      <div className="row">
                           <div className="col-lg-12 mx-auto d-block text-center">
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/classicn10.png" className="img-fluid"/></LazyLoad>
                           </div>
                      </div>           
                      <div className="row">
                        <div className="col-lg-11">
                           <HomeTopperX/>
                        </div>
                        <div className="col-lg-1 topperbtn">
                        <Link to="/Topperx"><button><i class="bi bi-arrow-right"></i></button></Link>
                        </div>
                      </div>
              </div> 
       </div>
  </div>
</div>
<div className="homegallery">
<div className="container">
       <div className="row">
           <div className="col-lg-12">
               <div className="maintitle">
                       <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/gallery.gif" className="img-fluid"/></LazyLoad>
                       <h2>School Gallery </h2>
                   </div>
               </div>
              <HomeGallery/>
              <div className="mx-auto d-block text-center">
                <button><Link to="PhotoGallery"> 
                <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/Arrow2.png" className="img-fluid"/></LazyLoad> | View All</Link> </button>
                </div>
</div>
</div>
</div>
<FooterForm/>

    </>
       
  )
}

export default Home
