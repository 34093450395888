import React, { useState } from 'react';
import { form1 } from '../Service/Api';
import { getHeader } from '../config';
import LazyLoad from 'react-lazyload';

function FooterForm() {
  // State for storing form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  // State for storing validation errors
  const [errors, setErrors] = useState({});

  // State for submission status
  const [submitStatus, setSubmitStatus] = useState('');

  // Validate email function
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  // Validate phone function
  const validatePhone = (phone) => {
    const re = /^\d{10}$/;  // Adjust regex according to your needs
    return re.test(phone);
  };

  // Validate form data
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!formData.name) {
      errors["name"] = "Name cannot be empty";
      formIsValid = false;
    }

    if (!formData.email) {
      errors["email"] = "Email cannot be empty";
      formIsValid = false;
    } else if (!validateEmail(formData.email)) {
      errors["email"] = "Invalid email address";
      formIsValid = false;
    }

    if (!formData.phone) {
      errors["phone"] = "Phone number cannot be empty";
      formIsValid = false;
    } else if (!validatePhone(formData.phone)) {
      errors["phone"] = "Invalid phone number, must be 10 digits";
      formIsValid = false;
    }

    if (!formData.message) {
      errors["message"] = "Message cannot be empty";
      formIsValid = false;
    }

    setErrors(errors);
    return formIsValid;
  };

  // Handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async(event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        // API call
        const type = 'ABC'; 
        const { header } = getHeader();
        const response = await form1 (formData, header, type);
        // Assuming the API sends back a success status
        console.log(response);
        if (response) {
          setSubmitStatus('Thank you for your feedback!');
          setFormData({ name: '', email: '', phone: '', message: '' }); // Reset form on success
        } else {
          setSubmitStatus('Failed to send message. Please try again.');
        }
      } catch (error) {
        console.error('Submission Error:', error);
        setSubmitStatus('Failed to send message. Please try again.');
      }
      setErrors({}); // Clear errors on successful submission
    } else {
      setSubmitStatus('Please fill all fields correctly.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="footerform">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 getin">
              <h3>Get in touch</h3>
            </div>
            <div className="col-lg-7">
              <div className="formbox">
                <h2>FEEDBACK FORM</h2>
                <div className="formmainbox">
                  <div className="formboxleft">
                    <div className="form-group">
                      <label>Name</label>
                      <input type="text" className="form-control" placeholder="Enter Your Name" name="name" value={formData.name} onChange={handleChange} />
                      <div className="text-danger">{errors.name}</div>
                    </div>
                    <div className="form-group">
                      <label>Email Address</label>
                      <input type="email" className="form-control" placeholder="Enter Your Email Address" name="email" value={formData.email} onChange={handleChange} />
                      <div className="text-danger">{errors.email}</div>
                    </div>
                    <div className="form-group">
                      <label>Contact Number</label>
                      <input type="text" className="form-control" placeholder="Enter Your Contact Number" name="phone" value={formData.phone} onChange={handleChange} />
                      <div className="text-danger">{errors.phone}</div>
                    </div>
                  </div>
                  <div className="formboxright">
                    <div className="form-group">
                      <label>Your Message</label>
                      <textarea className="form-control" placeholder="Type Your Message here" name="message" value={formData.message} onChange={handleChange}></textarea>
                      <div className="text-danger">{errors.message}</div>
                    </div>
                  </div>
                </div>
                <div className="mx-auto d-block text-center">
                  <button type="submit">
                    <p>
                      <LazyLoad><img src="https://webapi.entab.info/api/image/STMST/public/Images/Arrow2.png" className="img-fluid" alt="Submit"/> </LazyLoad>| SUBMIT </p>
                  </button>
                </div>
                {/* Display submission status */}
                {submitStatus && <p className="text-center submit-status">{submitStatus}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default FooterForm;
