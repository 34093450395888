import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SlideshowLightbox } from 'lightbox.js-react';
import { getEventsinner } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const Events = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedMonth, setSelectedMonth] = useState("All");
  const [monthOptions, setMonthOptions] = useState(["All"]);
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const galleryData = await getEventsinner();
      setData(galleryData);

      // Extract unique years
      const uniqueYears = Array.from(
        new Set(
          galleryData.flatMap((item) => {
            if (item.date) {
              const year = new Date(item.date).getFullYear();
              return [year];
            } else {
              return [];
            }
          })
        )
      );
      const uniqueYearRanges = uniqueYears.map((year) => `${year}`);
      uniqueYearRanges.sort((a, b) => b - a); // Sort years in descending order
      setYearRanges(uniqueYearRanges);

      // Automatically set the current year as the default selected year
      const currentYear = new Date().getFullYear();
      if (uniqueYearRanges.includes(`${currentYear}`)) {
        setSelectedYear(`${currentYear}`);
      } else {
        setSelectedYear("All");
      }

      // Extract unique months
      const uniqueMonths = Array.from(
        new Set(
          galleryData.flatMap((item) => {
            if (item.date) {
              const month = new Date(item.date).getMonth() + 1;
              return [month];
            } else {
              return [];
            }
          })
        )
      );
      uniqueMonths.sort((a, b) => b - a); // Sort months in descending order
      setMonthOptions(uniqueMonths.map(month => `${month}`));
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filteredData = data;

    // Filter by selected year
    if (selectedYear !== "All") {
      filteredData = filteredData.filter((item) => {
        if (item.date && item.date.trim() !== "") {
          const year = new Date(item.date).getFullYear();
          return parseInt(selectedYear) === year;
        }
        return false;
      });
    }

    // Filter by selected month
    if (selectedMonth !== "All") {
      filteredData = filteredData.filter((item) => {
        if (item.date && item.date.trim() !== "") {
          const month = new Date(item.date).getMonth() + 1;
          return parseInt(selectedMonth) === month;
        }
        return false;
      });
    }

    // Filter by search query
    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredData(filteredData);
  }, [selectedYear, selectedMonth, searchQuery, data]);

  const emptyArray = [
    { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/STMST/public/Images/events-pic.jpg" },
    { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/STMST/public/Images/events-pic.jpg" },
    { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/STMST/public/Images/events-pic.jpg" },
    { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/STMST/public/Images/events-pic.jpg" },
  ];

  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/"> Home</Link> </li>
          <li> Events </li>
        </ul>
      </div>

      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1> Events </h1>
            </div>
          </div>

          <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-md-3 col-12">
              <div className="count-val">
                <p>Total Count: {filterData.length} </p>
              </div>
            </div>

            <div className="col-xxl-3 col-xl-3 col-md-3 col-12">
              <div className="month-selection">
                <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} className="yearSelection">
                  <option value={"All"}>All Year</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>{yr}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-xxl-3 col-xl-3 col-md-3 col-12">
              <div className="month-selection">
                <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} className="monthSelect" >
                  <option value={"All"}>All Month</option>
                  {monthOptions.map((month, index) => (
                    <option key={index} value={month}>{month}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-xxl-3 col-xl-3 col-md-3 col-12">
              <div className="searchBox">
                <input type="text" id="myInput" name="name" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search Here.." />
              </div>
            </div>
          </div>

          <div className="row years year2024 showYear">
            {/* Render filtered data */}
            {filterData.length > 0 ? filterData.map((item, index) => (
              <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount">
                <div className="eventbox">
                  <div className="eventboximg">
                    <div className="eventattch">
                      <Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank">
                        <i className="bi bi-paperclip"></i>
                      </Link>
                    </div>
                    <LazyLoad>
                      <SlideshowLightbox>
                        <img src={`https://webapi.entab.info/api/image/${item.images[0]}`} className="img-fluid border-img w-full rounded" alt="Event" />
                      </SlideshowLightbox>
                    </LazyLoad>
                  </div>
                  <div className="eventboxdesc">
                    <div className="eventpgh">
                      <h5>{item.title}</h5>
                      <p>{item.description}</p>
                    </div>
                    <div className="eventdate">
                      <span><i className="bi bi-alarm"></i> {item.time}</span>
                      <span><i className="bi bi-calendar"></i> {new Date(item.date).getDate()}-{new Date(item.date).getMonth()+1}-{new Date(item.date).getFullYear()}</span>
                      <span><i className="bi bi-geo-alt"></i> {item.venue}</span>
                    </div>
                  </div>
                </div>
              </div>
            )) : emptyArray.map((item, index) => (
              <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount">
                <div className="eventbox">
                  <div className="eventboximg">
                    <div className="eventattch">
                      <Link to={`https://webapi.entab.info/api/image/${item.attachments}`} target="_blank">
                        <i className="bi bi-paperclip"></i>
                      </Link>
                    </div>
                    <LazyLoad>
                      <SlideshowLightbox>
                        <img src={item.attachments} className="img-fluid border-img w-full rounded" alt="Event" />
                      </SlideshowLightbox>
                    </LazyLoad>
                  </div>
                  <div className="eventboxdesc">
                    <div className="eventpgh">
                      <h5>{item.title}</h5>
                      <p>{item.description}</p>
                    </div>
                    <div className="eventdate">
                      <span><i className="bi bi-alarm"></i> {item.time}</span>
                      <span><i className="bi bi-calendar"></i> {item.date}</span>
                      <span><i className="bi bi-geo-alt"></i> {item.venue}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
